
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /* eslint-disable react/prop-types */
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { gsap, CSSPlugin } from 'gsap';
import BugsnagWrapper from '../src/components/BugsnagWrapper';
import RouterLoader from '../src/components/RouterLoader';
import '../src/styles/app.scss';
import '../src/styles/slick-carousel/slick-carousel.scss';
import '../src/styles/one-trust/footer-link-overrides.scss';
import { initSegmentQueue } from '../src/utils/analytics';
import RedirectBanner from '../src/components/RedirectBanner';
import SegmentIdentify from '../src/components/SegmentIdentify';
import UserProvider from '../src/contexts/user-context';
import FeatureFlagProvider from '../src/contexts/feature-flag-context';
import NavProvider from '../src/contexts/nav-context';
import LocaleRouter from '../src/components/LocaleRouter';
import 'focus-visible';
import useApollo from '../src/hooks/useApollo';
gsap.registerPlugin(CSSPlugin);
if (process.browser) {
    initSegmentQueue();
}
function MyApp({ Component, pageProps }) {
    const apolloClient = useApollo(pageProps.initialState);
    const { navData, footerData } = pageProps;
    return (<>
      <LocaleRouter />
      <RouterLoader />
      <BugsnagWrapper>
        <ApolloProvider client={apolloClient}>
          <FeatureFlagProvider>
            <NavProvider value={{ navData, footerData }}>
              <UserProvider>
                <SegmentIdentify />
                <RedirectBanner pageProps={pageProps}/>
                <div style={{ position: 'relative' }}>
                  <Component {...pageProps}/>
                </div>
              </UserProvider>
            </NavProvider>
          </FeatureFlagProvider>
        </ApolloProvider>
      </BugsnagWrapper>
      <div id="full-screen-effects"/>
    </>);
}
const __Next_Translate__Page__19229addb01__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__19229addb01__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  