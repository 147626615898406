/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './ButtonNew.module.scss';
import Svg from '../Svg';

export const getPrimaryClassName = (theme, color) => {
  let className;
  if (color === 'purple') {
    className = theme === 'light' ? s.lightPrimaryPurple : s.darkPrimaryPurple;
  } else if (color === 'gold') {
    className = theme === 'light' ? s.lightPrimaryGold : s.darkPrimaryGold;
  } else {
    className = theme === 'light' ? s.lightPrimaryDefault : s.darkPrimaryDefault;
  }
  return className;
};

/**
 * Used to get CSS class name off of imported css modules [s]
 * Class names follow the pattern: `${theme}${Level}${Color}` where level and color are capitalized
 * Attempting to string build s[`${theme}${Level}${Color}`] doesn't pull in the styles correctly
 * @param {string} theme
 * @param {string} level
 * @param {string} color
 * @return - correct css module imported via 's'
 */
export const getClassName = (theme, level, color, size) => {
  let className;
  if (
    theme === 'gryffindor' ||
    theme === 'slytherin' ||
    theme === 'hufflepuff' ||
    theme === 'ravenclaw'
  ) {
    className = [s.modern, s[theme], s[size], className].join(' ');
  } else if (theme === 'modern' || theme === 'link') {
    className = [s[theme], s[level], s[size], s[color], className].join(' ');
  } else if (level === 'tertiary') {
    className = theme === 'light' ? s.lightTertiary : s.darkTertiary;
  } else if (level === 'secondary') {
    if (color === 'gold') {
      className = s.goldSecondary;
    } else {
      className = theme === 'light' ? s.lightSecondary : s.darkSecondary;
    }
  } else {
    className = getPrimaryClassName(theme, color);
  }
  return className;
};

const propTypes = {
  label: PropTypes.string.isRequired,
  theme: PropTypes.oneOf([
    'light',
    'dark',
    'modern',
    'link',
    'gryffindor',
    'slytherin',
    'hufflepuff',
    'ravenclaw',
  ]),
  level: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['default', 'purple', 'gold']),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  ctaIcon: PropTypes.string,
  labelAttributes: PropTypes.objectOf(PropTypes.any),
  icon: PropTypes.string,
  svgIcon: PropTypes.shape({}),
  iconAlign: PropTypes.oneOf(['left', 'right']),
  id: PropTypes.string,
  onClick: PropTypes.func,
  dataAttribute: PropTypes.string,
  buttonType: PropTypes.string,
  collapseWidth: PropTypes.bool,
  size: PropTypes.string,
  isGradient: PropTypes.bool,
};

const ButtonNew = ({
  label,
  theme = 'dark',
  level = 'primary',
  color = 'default',
  disabled = false,
  onClick = () => {},
  isLoading = false,
  id = null,
  className = '',
  ctaIcon = null,
  labelAttributes = {},
  icon = null,
  svgIcon = null,
  iconAlign = 'left',
  dataAttribute = '',
  buttonType = 'button',
  collapseWidth = false,
  size = 'large',
  isGradient = false,
  ...props
}) => {
  const dynamicAttribute = {};
  if (dataAttribute) dynamicAttribute[dataAttribute] = 'true';

  /* eslint-disable react/button-has-type */
  return (
    <button
      id={id}
      type={buttonType}
      className={[
        getClassName(theme, level, color, size),
        icon || svgIcon ? s.withIcon : '',
        isGradient ? s.isGradient : '',
        ctaIcon ? s.withCtaIcon : '',
        iconAlign === 'right' ? s.iconAlignRight : '',
        className,
        collapseWidth ? s.collapseWidth : '',
      ].join(' ')}
      disabled={disabled}
      onClick={onClick}
      {...dynamicAttribute}
      {...props}
    >
      <div className={cx({ [s.dimmed]: isLoading })}>
        {icon && <Svg color="white" icon={icon} width={14} height={14} viewBox="0 0 18 18" />}
        {ctaIcon && <img className={s.ctaIcon} alt="icon" src={ctaIcon} />}
        {svgIcon && svgIcon}
        <span className={s.label} {...labelAttributes}>
          {label}
        </span>
      </div>
      {isLoading && <span className={s.spinner} />}
    </button>
  );
  /* eslint-enable react/button-has-type */
};

ButtonNew.propTypes = propTypes;
export default ButtonNew;
